import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import LabeledInput from "../basics/input/labeled-input/LabeledInput";
import "./_cage-calculator.scss"
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";

export class CageCalculator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gain: props.gain === "-" || !+props.gain ? convertWeightUnitTo(8000, {
                unit: UnitTypes.MEDIUM,
                fixed: 1,
                rawValue: true
            }) : convertWeightUnitTo(+props.gain, {
                unit: UnitTypes.MEDIUM,
                fixed: 1,
                rawValue: true
            }),
            weight: props.weight === "-" || !+props.weight ? convertWeightUnitTo(150000, {
                unit: UnitTypes.MEDIUM,
                fixed: 1,
                rawValue: true
            }) : convertWeightUnitTo(+props.weight, {
                unit: UnitTypes.MEDIUM,
                fixed: 1,
                rawValue: true
            }),
            date: moment.utc().startOf("day"),
            animalsNumber: props.animalsNumber || 400
        }
    }

    calculateExpectedForAggData(data, date) {
        let diffBetweenDataAndDays = date.diff(moment.utc(data.AggTi), "days");
        let gainToAdd = Math.round(diffBetweenDataAndDays * this.state.gain);
        let averagePasses = (data.AggDt[0].VisAll ? data.AggDt[0].VisAll.reduce((a, b) => a + b, 0) : 0) / this.state.animalsNumber;
        // wyliczenie ilosci zwierzat na komorze w poszczegolnych wagach
        let animalsInWeights = data.AggDt[0].VisWeight.map(visits => Math.round(visits / averagePasses));
        // ograniczenie tablicy od wagi minimalnej
        let minWeight = Math.round(this.state.weight - gainToAdd);
        minWeight = minWeight < 10 ? 10 : minWeight;
        minWeight = minWeight > 180 ? 180 : minWeight;
        let inMinimumWeight = animalsInWeights.slice((minWeight * 2) - 10 - 1);
        let expected = inMinimumWeight.reduce((a, b) => a + b, 0);
        return expected > this.state.animalsNumber ? this.state.animalsNumber : expected;
    }

    calculatePigs() {
        try {
            let cageData = this.props.cageData.get(this.props.cage.DevID);
            // wyszukanie najblizszych danych do wybranej daty
            cageData.sort((a, b) => b.AggTi - a.AggTi);
            let data = cageData.find(item => item.AggTi <= this.state.date.toDate().getTime());
            console.log(data);
            let expected = this.calculateExpectedForAggData(data, this.state.date);
            // wyliczyenie danych dla wykresu dla kolejnych 50 dni od wybranej daty
            let chartData = [{
                name: this.state.date.format("DD.MM.YYYY"),
                expected
            }];
            // liczenie od 1, bo aktualnie wybrany dzien jest juz wyliczony
            for (let i = 1; i < 50; i++) {
                let date = this.state.date.clone().add(i, "days");
                let obj = {
                    name: date.format("DD.MM.YYYY")
                };
                let aggDataForDay = cageData.find(item => item.AggTi <= date.toDate().getTime());
                obj.expected = this.calculateExpectedForAggData(aggDataForDay, date);
                chartData.push(obj);
            }
            // zwrocenie ilosci zwierzat od wagi minimalnej
            return {
                expected,
                chartData
            };
        } catch (e) {
            console.error(e);
            return {
                expected: 0,
                chartData: []
            };
        }
    }

    onAnimalsNumberChange = value => {
        this.setState({
            animalsNumber: +value
        })
    };

    onWeightChange = value => {
        this.setState({
            weight: +value
        })
    };

    onDateChange = value => {
        this.setState({
            date: moment.utc(value)
        })
    };

    onGainChange = value => {
        this.setState({
            gain: +value
        })
    };

    render() {
        const {date, weight, animalsNumber, gain} = this.state;
        let animalsInWeight = this.calculatePigs();
        console.log(animalsInWeight);
        const chartDef = [
            {
                color: "green",
                dataKey: "expected",
                name: this.props.t("animalCount")
            }
        ];
        return (
            <div className="cage-calculator">
                <div className="cage-calculator-data">
                    <LabeledInput label={this.props.t("chamber.calculator.animalNumber")} type="number"
                                  value={animalsNumber}
                                  onChange={this.onAnimalsNumberChange}/>
                    <LabeledInput label={this.props.t("averageWeight")} type="number"
                                  unit={getUnit("weight", UnitTypes.MEDIUM)} value={weight}
                                  onChange={this.onWeightChange}/>
                    <LabeledInput label={this.props.t("date")} type="date"
                                  value={date.format("YYYY-MM-DD")}
                                  onChange={this.onDateChange}/>
                    <LabeledInput label={this.props.t("gain")} type="number" value={gain}
                                  unit={getUnit("weight", UnitTypes.MEDIUM)}
                                  onChange={this.onGainChange}/>
                    {this.props.t("chamber.calculator.inDay")} {date.format("DD.MM.YYYY")} {this.props.t("chamber.calculator.is")}&nbsp;
                    <strong>{animalsInWeight.expected}</strong> {this.props.t("chamber.calculator.pigsInWeight")}
                    <strong>{weight} {getUnit("weight", UnitTypes.MEDIUM)}</strong>
                </div>
                <div className="cage-calculator-chart">
                    <Chart dataDef={chartDef} data={animalsInWeight.chartData} Yaxis={{
                        name: this.props.t("animalCount")
                    }} Xaxis={{
                        name: this.props.t("date"),
                        dataKey: "name"
                    }} type={"Bar"}/>
                </div>
            </div>
        );
    }

}

CageCalculator = connect(state => ({
    cageData: state.aggregatedData.data,
}))(CageCalculator);

CageCalculator.propTypes = {
    cage: PropTypes.object.isRequired,
    gain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    animalsNumber: PropTypes.number
};

export default withTranslation()(CageCalculator);