import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";

class TextSwitchItem extends Component {
    onClick = () => {
        const {onChange, value} = this.props;
        if(_.isFunction(onChange)){
            onChange(value)
        }
    };
    render() {
        const {name, selected} = this.props;
        return (
            <div onClick={this.onClick} className={selected ? "item selected" : "item"}>
                {name}
            </div>
        );
    }
}

TextSwitchItem.propTypes = {
    value: PropTypes.oneOf([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
};

export default TextSwitchItem;