import React from "react";
import TreeSelect from "./TreeSelect";
import PropTypes from "prop-types";

export default class ReduxTreeSelect extends React.Component {

    constructor(props) {
        super(props);
        let initialValue = props.value;
        if (props.options && props.options.length > 0 && !props.value) {
            initialValue = props.options[0];
        }
        this.onChange(initialValue)
    }

    onChange = (value) => {
        this.props.input.onChange(value);
    };

    render() {
        const {options, expanded, meta: {touched, error}, input: {value}} = this.props;
        return (
            <TreeSelect options={options} expanded={expanded} onChange={this.onChange}
                        error={touched && error ? error : ""} value={value}/>
        );
    }

}

ReduxTreeSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
        name: PropTypes.string.isRequired, // sciezka do nazwy
        object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired, // obiekt, ktory zawiera opcjonalne kolejne tablice
        children: PropTypes.array, // kolejne podobiekty
        key: PropTypes.string
    }), PropTypes.string])).isRequired,
    expanded: PropTypes.bool
};