import React from "react";
import "./_tree-select.scss"
import Input from "../input/Input";
import {Fade} from "react-bootstrap";
import PropTypes from "prop-types";
import TreeSelectItem from "./TreeSelectItem";
import {get, isEqual, isNil} from "lodash"
import {myID} from "../../../libs/generateID";
import {isMobile} from "../../../utils/MobileUtils";
import ReactDOM from "react-dom";

export default class TreeSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            inputValue: props.value ? get(props.value.object, props.value.name, "") : ""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.value, this.props.value)) {
            if (isNil(this.props.value)) {
                this.setState({
                    inputValue: ""
                })
            } else {
                this.setState({
                    inputValue: get(this.props.value.object, this.props.value.name, "")
                })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.state.menuOpened && this.tree && this.menu && !this.tree.contains(event.target) && !this.menu.contains(event.target)) {
            this.setState({
                menuOpened: false
            })
        }
    };

    onClickInput = () => {
        this.setState(state => ({
            menuOpened: !state.menuOpened
        }))
    };

    onSelectItem = (value) => {
        if (value) {
            this.setState(state => ({
                inputValue: get(value.object, value.name, ""),
                menuOpened: isMobile() ? false : state.menuOpened
            }));
        } else {
            this.setState(state => ({
                inputValue: "",
                menuOpened: isMobile() ? false : state.menuOpened
            }))
        }
        this.props.onChange(value);
    };

    createTree = (options, level = 0) => {
        let divs = [];
        for (let option of options) {
            let children = option.children ? this.createTree(option.children, level + 1) : [];
            let div = (
                <TreeSelectItem option={option} level={level} onSelect={this.onSelectItem}
                                key={get(option.object, option.key, myID())}
                                selected={this.props.value && isEqual(this.props.value.object, option.object)}
                                expanded={option.key === "BgID" ? this.props.expanded : false}>
                    {children}
                </TreeSelectItem>
            );
            divs.push(div);
        }
        return divs;
    };

    render() {
        let isMob = isMobile();
        let widthOfMenu = 0, menuLeft = 0, menuTop = 0;
        if (this.tree) {
            let rect = this.tree.getBoundingClientRect();
            widthOfMenu = rect.width;
            menuLeft = rect.left;
            menuTop = rect.top + rect.height + window.scrollY;
        }
        return (
            <div className={isMob ? "tree-select mobile" : "tree-select"} ref={ref => this.tree = ref}>
                <Input placeholder={this.props.placeholder} type="text" readOnly
                       onClick={!this.props.disabled ? this.onClickInput : null}
                       value={this.state.inputValue}
                       error={this.props.error} disabled={this.props.disabled}/>
                <div className="tree-select-icon">
                    {
                        !!this.props.clearButton &&
                        <i className="fas fa-times pointer mr-1" onClick={() => this.onSelectItem(null)}/>

                    }
                    <i className={this.state.menuOpened ? "fas fa-caret-up" : "fas fa-caret-down"}
                       onClick={this.onClickInput}/>
                </div>
                {
                    ReactDOM.createPortal((
                        <Fade in={this.state.menuOpened} unmountOnExit>
                            <div className={!isMob ? "tree-select-menu" : "tree-select-menu mobile"}
                                 style={!isMob ? {width: widthOfMenu, left: menuLeft, top: menuTop} : {}}
                                 ref={ref => this.menu = ref}>
                                {
                                    isMob &&
                                    <div className="close-button" onClick={this.onClickInput}>
                                        <i className="fas fa-times"/>
                                    </div>
                                }
                                {this.createTree(this.props.options)}
                            </div>
                        </Fade>
                    ), document.getElementById("root") || document.createElement("div"))
                }
            </div>
        );
    }

}

TreeSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired, // sciezka do nazwy
        object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired, // obiekt, ktory zawiera opcjonalne kolejne tablice
        children: PropTypes.array, // kolejne podobiekty
        key: PropTypes.string
    })).isRequired,
    expanded: PropTypes.bool,
    placeholder: PropTypes.string,
    clearButton: PropTypes.bool,
    onChange: PropTypes.func,
    initialValue: PropTypes.shape({
        name: PropTypes.string.isRequired, // sciezka do nazwy
        object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired, // obiekt, ktory zawiera opcjonalne kolejne tablice
    }),
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    disabled: PropTypes.bool
};

TreeSelect.defaultProps = {
    expanded: false,
    onChange: () => {
    },
    initialValue: null,
    clearButton: false
};
