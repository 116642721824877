import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import "react-virtualized/styles.css";
import {getRemToPx} from "../../../utils/DOMUtils";
// import ClimateRow from "../../devices-rows/climateWST/ClimateRow";
import Col from "react-bootstrap/Col";
import {isFunction} from "lodash";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller} from "react-virtualized";
import {Row} from "react-bootstrap";

class VirtualizedList extends Component {

    constructor(props) {
        super(props);
        this.cache = new CellMeasurerCache({
            defaultHeight: getRemToPx(7),
            fixedWidth: true,
            keyMapper: (rowIndex) => rowIndex
        });

    }


    onRowsRendered = () => {
        //todo: przekazac customowy callback np. wysylanie informacji o tym co uzytkownik wyswietla
    }

    rowRenderer = ({index, style, key: keyProps, parent}) => {
        const {dataTransform, data, component, passProps = {}} = this.props;
        const rowData = dataTransform(data[index]);
        const _component = isFunction(component) ? component(data[index]) : component;
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={keyProps}
                parent={parent}
                rowIndex={index}
            >
                {({measure, registerChild}) => (
                    <div style={style} ref={registerChild}>
                        {
                            React.createElement(_component, {
                                measure,
                                ...passProps,
                                ...rowData
                            })
                        }
                    </div>
                )}
            </CellMeasurer>
        );
    };

    clearCache = () => {
        this.cache.clearAll();
    }

    render() {
        const {data, noRowsRenderer, forceRerender} = this.props;
        return (
            <Row>
                <Col className={"d-flex d-flex flex-column w-100 mh-15rem"}>
                    <Row>
                        <WindowScroller onResize={this.clearCache}>
                            {
                                ({height, scrollTop, onChildScroll, isScrolling}) =>
                                    <AutoSizer className={"w-100 background-inherit"} disableHeight
                                               defaultWidth={window.innerWidth}>
                                        {({width}) =>
                                            <List
                                                rowCount={data.length}
                                                width={width}
                                                forceRerender={forceRerender}
                                                rowRenderer={this.rowRenderer}
                                                scrollTop={scrollTop}
                                                autoHeight
                                                height={height}
                                                onRowsRendered={this.onRowsRendered}
                                                deferredMeasurementCache={this.cache}
                                                rowHeight={this.cache.rowHeight}
                                                isScrolling={isScrolling}
                                                onScroll={onChildScroll}
                                                overscanRowCount={3}
                                                className={"w-100 overflow-auto"}
                                                noRowsRenderer={noRowsRenderer}
                                            />
                                        }

                                    </AutoSizer>
                            }
                        </WindowScroller>
                    </Row>
                </Col>
            </Row>
        );
    }
}

VirtualizedList.propTypes = {
    data: PropTypes.array.isRequired,
    component: PropTypes.node.isRequired,
    dataTransform: PropTypes.func,
    forceRerender: PropTypes.any //jesli wartosc tego pola sie zmieni to virtualized sie odrenderuje na nowo
};

VirtualizedList.defaultProps = {
    dataTransform: (dataRow) => dataRow
}
export default VirtualizedList;
