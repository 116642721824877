import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {Col, Row} from "react-bootstrap";
import CageMarkerItem from "./CageMarkerItem";
import {connect} from "react-redux";
import {convertWeightUnitTo} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {withTranslation} from "react-i18next";


class CageMarker extends React.Component {

    getValues = () => {
        const {shadows, cage} = this.props;
        return {
            fillMk1: _.get(shadows.get(cage.DevID), 'markers[0].dyeUsage', 0) || 0,
            fillMk2: _.get(shadows.get(cage.DevID), 'markers[1].dyeUsage', 0) || 0,
            workTypeMk1: _.get(shadows.get(cage.DevID), 'markers[0].workType'),
            workTypeMk2: _.get(shadows.get(cage.DevID), 'markers[1].workType'),
            minWeightMk1: _.get(shadows.get(cage.DevID), 'markers[0].minWeight'),
            minWeightMk2: _.get(shadows.get(cage.DevID), 'markers[1].minWeight'),
            maxWeightMk1: _.get(shadows.get(cage.DevID), 'markers[0].maxWeight'),
            maxWeightMk2: _.get(shadows.get(cage.DevID), 'markers[1].maxWeight')

        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props, nextProps)) {
            return true;
        }
        return false;
    };

    formatWeight = (weight, showUnit) => {
        return convertWeightUnitTo(weight, {unit: UnitTypes.MEDIUM, showUnit: !!showUnit, fixed: 0})
    };

    renderDecs = (workType, minWeight, maxWeight) => {
        const {t} = this.props;
        return (
            <>
                <b>{_.isNil(workType) ? '-' : t("worktype") + ': ' + t(`modals.cageMarkerModal.exit.${workType}`).toLowerCase()}</b>{' '}{!!workType && <>{(minWeight === 0 && maxWeight === 0) ? `${t("modals.cageMarkerModal.weightRange").toLowerCase()} ${t("all")}` : `${t("modals.cageMarkerModal.weightRange")} ${this.formatWeight(minWeight)}-${this.formatWeight(maxWeight, true)}`}</>}
            </>
        )
    };

    render() {
        const {cage} = this.props;
        const {
            fillMk1, fillMk2, workTypeMk1, workTypeMk2, minWeightMk1, minWeightMk2, maxWeightMk1, maxWeightMk2
        } = this.getValues();
        const [marker1, marker2] = _.get(cage, 'Settings.Marker', []);
        return (
            <Row className="cage-marker-container">
                {
                    marker1 &&
                    <Col md={6} className="marker-col">
                        <CageMarkerItem markerNumber={1} filled={fillMk1} fluidColor={marker1.Color}
                                        text={`${fillMk1.toFixed(0)}%`}/>
                        {this.renderDecs(workTypeMk1, minWeightMk1, maxWeightMk1)}
                    </Col>
                }
                {
                    marker2 &&
                    <Col md={6} className="marker-col">
                        <CageMarkerItem markerNumber={2} filled={fillMk2} fluidColor={marker2.Color}
                                        text={`${fillMk2.toFixed(0)}%`}/>
                        {this.renderDecs(workTypeMk2, minWeightMk2, maxWeightMk2)}
                    </Col>
                }

            </Row>
        )
    }

}


CageMarker.propTypes = {
    cage: PropTypes.object.isRequired
};
CageMarker = connect(state => ({
    shadows: state.shadows.shadows,
}))(CageMarker);
export default withTranslation()(CageMarker)



