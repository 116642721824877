import React from "react";
import "./_text-switch.scss";
import {isEqual} from "lodash";

export default class ReduxTextSwitch extends React.Component {

    onChange = (newValue) => {
        this.props.input.onChange(newValue)
    };

    render() {
        const {options, className, input: {value}} = this.props;
        const selectedIndex = options.findIndex(option => isEqual(value, option.value));
        return (
            <div
                className={`fetura-text-switch size-${options.length} pos-${selectedIndex}${className ? ` ${className}` : ""}`}>
                {
                    options.map((option, index) => (
                        <div onClick={e => this.onChange(option.value)}
                             className={(selectedIndex === index) ? "item selected" : "item"}>
                            {option.name}
                        </div>
                    ))
                }
            </div>
        )
    }
}
