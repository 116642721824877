import React from 'react';
import Highlight from "../highlight/Highlight";
import {copyToClipboard} from "../../../utils/TextUtils";
import packageJson from "../../../../package.json";
import {connect} from "react-redux";
import {detect} from "detect-browser";
import {withRouter} from "react-router-dom";
import './_version-footer.scss';
import {isMobile} from "../../../utils/MobileUtils";
import {get, isEmpty} from "lodash";
import moment from "moment";
import {withCookies} from "react-cookie";

const STAGE = process.env.REACT_APP_STAGE || "local";
const _DateFormat = "DD.MM.YYYY HH:mm";

class VersionFooter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            browser: detect(),
            event: null,
            farm: undefined,
            leftBar: document.getElementsByClassName("left-bar")
        }
    }

    static getClassName() {
        return isMobile() ? 'mobile-version' : 'desktop';
    };

    static getDerivedStateFromProps(props, state) {
        if (state.farm !== props.farm && !!props.farm) {
            return {
                farm: props.farm
            }
        }
        return null;
    }

    componentDidMount() {
        const {cookies} = this.props;
        let expanded = cookies.get("expanded");
        this.setState({
            expanded: "true" !== expanded && "false" !== expanded ? true : "true" === expanded,
        })
    }

    combineClipBoard() {
        const {farm, user: {ClientID, LocalUserID}} = this.props;
        const {browser} = this.state;
        const version = STAGE.substring(0, 3) + process.env.REACT_APP_BUILD_DATE_TIME + ' ' + packageJson.version;
        const platform = browser.os + " (" + browser.name + "-" + browser.version + ")";
        return `| ${ClientID ? ClientID : ''} | ${LocalUserID ? LocalUserID : ''} | ${farm ? farm : ''} | ${version} | ${platform} |`;
    }

    copyAndRedirect = () => {
        copyToClipboard(this.combineClipBoard());
        this.redirect();
    };

    redirect = () => {
        this.props.history.push('/changeLog');
    };

    isLeftBar = () => {
        const {leftBar} = this.state;
        return leftBar.length > 0
    };

    willExpanded = () => {
        const {cookies} = this.props;
        let expanded = cookies.get("expanded");
        return (!this.isLeftBar() ? true : "true" !== expanded && "false" !== expanded ? true : "true" === expanded)
    };

    showFooter = (expanded) => {
        return (expanded ? VersionFooter.getClassName() : VersionFooter.getClassName() + " hide")
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {farm, leftBar} = this.state;

        if (prevProps.farm !== farm) {
            this.setState({
                farm: this.props.farm,
            });
        }
        if (this.willExpanded() !== this.state.expanded) {
            this.setState({
                expanded: this.willExpanded()
            });
        }

        if (leftBar !== prevState.leftBar) {
            this.setState({
                leftBar: document.getElementsByClassName("left-bar")
            });
        }
    }

    render() {
        const {farm, expanded} = this.state;
        const {status} = this.props;
        return (
            <div className={'version'}>
                <div
                    className={this.showFooter(expanded)}>
                    <Highlight
                        onClick={this.copyAndRedirect}
                    >
                        {expanded &&
                        <p> {'F: ' + STAGE.substring(0, 3)}_{process.env.REACT_APP_BUILD_DATE_TIME}{' '}</p>}
                        <Highlight highlightColor={'info'}>
                            <b>{packageJson.version}</b>
                        </Highlight>
                    </Highlight>
                </div>
                {
                    !!farm && !isEmpty(status) && <div className={this.showFooter(expanded)}>
                        <Highlight onClick={this.redirect}>
                            {expanded &&
                            <p>{'B: ' + moment(get(status.values().next().value, 'compilation', +new Date())).format(_DateFormat)}{' '}</p>}
                            <Highlight highlightColor={'info'}>
                                <b>{get(status.values().next().value, 'version', '-')}</b>
                            </Highlight>
                        </Highlight>
                    </div>
                }
            </div>
        );

    }
}

VersionFooter = connect(state => ({
    selectedLang: state.language.lang.lang,
    farm: state.location.farm,
    user: state.user.user,
    status: state.mqtt.status,
}))(VersionFooter);

VersionFooter = withCookies(VersionFooter);

export default withRouter(VersionFooter);
