import React from "react";
import "./_view-container-modal.scss"
import PropTypes from "prop-types";
import Header from "../header/Header";
import ViewContainer from "../view-container/ViewContainer";
import {CSSTransition} from "react-transition-group";
import ReactDOM from "react-dom";
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../../utils/DOMUtils";
import LoadingComponent from "../../loading/LoadingComponent";

export default class ViewContainerModal extends React.Component {

    state = {
        show: !!this.props.show,
        isBusy: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.isBusy && !!nextProps.show !== !!prevState.show) {
            return {show: !!nextProps.show}
        }
        return null;
    }

    componentWillUnmount() {
        bodyUnlockScroll(ScrollLock.MODAL);
    }

    onHide = () => {
        this.setState({
            show: false,
            isBusy: true
        })
    };

    handleExited = () => {
        const {onHide} = this.props;
        this.setState({
            isBusy: false
        });
        onHide && onHide();
    };

    handleEnter = () => {
        this.setState({
            isBusy: false
        });
    };

    handleEntered = () => {
        bodyLockScroll(ScrollLock.MODAL);
    };

    handleExit = () => {
        bodyUnlockScroll(ScrollLock.MODAL);
    };

    render() {
        const {children, className: propsClassName, name, loading, onHide, addPaddingForButtons} = this.props;
        const {show} = this.state;
        const className = ["view-container-modal", propsClassName].filter(o => !!o).join(" ");
        return ReactDOM.createPortal((
            <CSSTransition in={show} timeout={300}
                           classNames="modal-in" mountOnEnter unmountOnExit appear onEnter={this.handleEnter}
                           onEntered={this.handleEntered} onExit={this.handleExit} onExited={this.handleExited}>
                <div className={className}>
                    <Header text={
                        <>
                            {
                                !!onHide &&
                                <span><i className={`fas fa-fw fa-arrow-${show ? "left" : "right"} pointer`}
                                         onClick={this.onHide}/></span>
                            }
                            {name}
                        </>
                    }/>
                    <ViewContainer addPaddingForButtons={addPaddingForButtons}>
                        <LoadingComponent type={"container"} isLoading={loading} bgInherit={true}/>
                        {children}
                    </ViewContainer>
                </div>
            </CSSTransition>

        ), document.getElementById("center-content") || document.createElement("div"));
    }

}

ViewContainerModal.propTypes = {
    show: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onHide: PropTypes.func,
    addPaddingForButtons: PropTypes.bool,
    className: PropTypes.string
};

ViewContainerModal.defaultProps = {
    show: false
};