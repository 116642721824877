import React from "react";
import _ from "lodash";
import {startMotor as startNRF, stopMotor as stopNRF} from "../../../IOT/device-functions/DispenserNRFFunctions";
import {startMotors as startDTM, stopMotors as stopDTM} from "../../../IOT/device-functions/DispenserDriverFunctions";
import InfoBox from "../../basics/info-box/InfoBox";
import Timer from "react-time-counter";
import Button from "../../basics/button/Button";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {withTranslation} from "react-i18next";

export class TestDispenserConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            started: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {device} = this.props;
        const {started} = this.state;
        if (!_.isEqual(device, nextProps.device)) {
            if (started) {
                this.stop(device);
            }
        }
    }

    start = (device) => {
        if (device) {
            const {DevID, Adr} = device;
            if (_.isNil(Adr)) {
                startNRF(DevID, {time: 5000}, () => this.setState({
                    started: true,
                    message: ""
                }));
            } else {
                startDTM(DevID, [{time: 5000, number: Adr + 1}], () => this.setState({
                    started: true,
                    message: ""
                }))
            }
        }
    };

    stop = (device) => {
        if (device) {
            const {DevID, Adr} = device;
            if (_.isNil(Adr)) {
                stopNRF(DevID, () => this.setState({
                    started: false
                }))
            } else {
                stopDTM(DevID, [Adr + 1], () => this.setState({
                    started: false
                }))
            }
        }
    };

    onConfirm = (props) => {
        this.props.onNextClick();
        props.handleHide();
    };

    render() {
        const {started} = this.state;
        const {device, children, t} = this.props;
        return (
            <div>
                {
                    started &&
                        <InfoBox boxColor={"info"}>
                            <Timer
                                backward={true}
                                seconds={5}
                                showHours={false}
                                showMinutes={false}
                                endLabel={"s"}
                                callback={() => this.setState({
                                    started: false,
                                }, () => {
                                    this.props.show('confirm-modal', {
                                        text: t("newSettings.feeding.forages.calibration.endMessage"),
                                        title: t("newSettings.feeding.forages.calibration.correctCalibration"),
                                        confirmText: t("yes"),
                                        onConfirmed: (modalProps) => this.onConfirm(modalProps)
                                    })
                                })}
                            />
                        </InfoBox>
                }
                {children}
                <hr/>
                <Button
                    style={{width: '100%'}}
                    type={"button"}
                    disabled={!device}
                    onClick={() => started ? this.stop(device) : this.start(device)}
                >
                    {started && t("disableDispenser")} {!started && t("newSettings.feeding.forages.calibration.startDispenser")}
                </Button>
            </div>
        )
    }
}

TestDispenserConnection = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(TestDispenserConnection);

export default withTranslation()(TestDispenserConnection);

