import React, {Component} from 'react';
import _ from "lodash";
import "./_tiny-list.scss";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

const _ItemRenderer = ({item}) => {
    if (_.isArray(item)) {
        return <TinyList list={item}/>
    } else if (_.isString(item) || _.isNumber(item)) {
        return item;
    } else {
        return null;
    }
};

const ItemRenderer = React.memo(_ItemRenderer);

class TinyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: !!props.loadExpanded,
            list: props.list || [],
            header: props.header || null,
            maxItems: props.maxItems || 0,
            notFoundText: props.notFoundText || props.t("notFound.dataNotFound"),
            className: ["fetura-tiny-list", props.className].filter(o => o).join(" ")
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newClassName = ["fetura-tiny-list", nextProps.className].filter(o => o).join(" ");
        if (
            !_.isEqual(prevState.list, nextProps.list)
            || (nextProps.header !== prevState.header)
            || (newClassName !== prevState.className)
        ) {
            return {
                list: nextProps.list || [],
                header: nextProps.header || null,
                notFoundText: nextProps.notFoundText || nextProps.t("notFound.dataNotFound") || "-",
                className: newClassName
            }
        }
        return null;
    }

    handleOnClick = () => {
        this.setState({
            expanded: true
        })
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState) || (!_.isEqual(this.props.t("basics.tinyList"), nextProps.t("basics.tinyList")))
    }

    render() {
        const {expanded, list, header, maxItems, className, notFoundText} = this.state;
        const {t} = this.props;
        const sliceEnd = !expanded && !!maxItems ? maxItems : undefined;
        const showExpandButton = !!maxItems && !expanded && (list.length > maxItems);
        return (
            <>
                {!!header && <h5>{header}</h5>}
                <ul className={className}>
                    {
                        !list.length &&
                        <li className={"opacity-50"}>-{notFoundText}-</li>
                    }
                    {
                        !!list.length &&
                        <>
                            {
                                list.slice(0, sliceEnd).map((item, index) =>
                                    <li key={`tiny_${index}`}>
                                        <ItemRenderer item={item}/>
                                    </li>
                                )
                            }
                            {showExpandButton &&
                            <li className={"pointer opacity-75 text-lowercase"} onClick={this.handleOnClick}
                                title={`...${list.slice(sliceEnd - 1).join(", ")}`}>
                                {t("basics.tinyList.showMore")} (+{list.length - sliceEnd})
                            </li>}
                        </>
                    }
                </ul>
            </>
        );
    }
}

TinyList.propTypes = {
    notFoundText: PropTypes.string,
    header: PropTypes.string,
    maxItems: PropTypes.string,
    list: PropTypes.array.isRequired,
    loadExpanded: PropTypes.bool,
    className: PropTypes.string
};

TinyList.defaultProps = {
    loadExpanded: false,
    list: [],
    maxItems: 0,
};

export default withTranslation()(TinyList);
