import React from "react";
import BridgeInterfaceSelector from "./BridgeInterfaceSelector";

export default class ReduxBridgeInterfaceSelector extends React.Component {

    onChange = val => {
        this.props.input.onChange(val);
    };

    render() {
        // TODO errory
        const {input: {value}, meta: {touched, error}, addressToCheck, device, bridge} = this.props;
        return (
            <BridgeInterfaceSelector value={value} onChange={this.onChange} error={touched && error ? error : ""}
                                     addressToCheck={addressToCheck} device={device} bridge={bridge}/>
        );
    }

}