import React from "react";
import "./_cage-marker-item.scss";
import PropTypes from "prop-types";
import _ from "lodash";
import {textContrast} from "../../utils/ColorUtils";


class CageMarkerItem extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(!_.isEqual(this.props, nextProps)){
            return true;
        }
        return false;
    }

    render() {
        const {fluidColor, filled, text, markerNumber} = this.props;
        let fluidLevel = Math.min(Math.max(0, 100-filled), 100);
        return (
            <div className={"cage-marker-item"}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 135.46666 135.46667"
                    id="cage-marker">
                    <path
                        d="M27.195239 21.636406C27.195239 21.636406 27.195239 36.788452 27.195239 36.788452C26.678278 37.189502 26.345162 37.81538 26.345162 38.52323C26.345162 38.52323 26.345162 55.865839 26.345162 55.865839C26.345162 56.57369 26.678278 57.199212 27.195239 57.600101C27.195239 57.600101 27.195239 111.97973 27.195239 111.97973C26.678156 112.38068 26.345162 113.00661 26.345162 113.71451C26.345162 113.71451 26.345162 131.05712 26.345162 131.05712C26.345162 132.27338 27.324107 133.25285 28.540378 133.25285C28.540378 133.25285 106.92629 133.25285 106.92629 133.25285C108.14256 133.25285 109.1215 132.27338 109.1215 131.05712C109.1215 131.05712 109.1215 113.71451 109.1215 113.71451C109.1215 113.00661 108.78852 112.38068 108.27143 111.97973C108.27143 111.97973 108.27143 57.600101 108.27143 57.600101C108.7884 57.199212 109.1215 56.57369 109.1215 55.865839C109.1215 55.865839 109.1215 38.52323 109.1215 38.52323C109.1215 37.81538 108.7884 37.189502 108.27143 36.788452C108.27143 36.788452 108.27143 21.636406 108.27143 21.636406C108.27143 21.636406 27.195239 21.636406 27.195239 21.636406"
                        id="cage-marker-back"/>
                    <path
                        d="M42.610057 1.8274089C37.365977 1.8274089 33.142547 2.8642489 33.122777 8.1035089C33.122777 8.1035089 28.311187 8.1035089 28.311187 8.1035089C24.609507 8.1035089 21.629427 11.083589 21.629427 14.785269C21.629427 14.785269 21.629427 24.225519 21.629427 24.225519C21.629427 27.927199 24.609507 27.732279 28.311187 27.732279C28.311187 27.732279 107.15548 27.732279 107.15548 27.732279C110.85716 27.732279 113.83724 27.927199 113.83724 24.225519C113.83724 24.225519 113.83724 14.785269 113.83724 14.785269C113.83724 11.083589 110.85716 8.1035089 107.15548 8.1035089C107.15548 8.1035089 102.34337 8.1035089 102.34337 8.1035089C102.32357 2.8642489 98.10068 1.8274089 92.85661 1.8274089C92.85661 1.8274089 42.610057 1.8274089 42.610057 1.8274089"
                        id="cage-marker-lid"/>
                    <text y={15} x={68} textAnchor="middle" width={135.46667} height={50} fill={'white'} dominantBaseline={"central"}>
                        {markerNumber}
                    </text>
                    <path
                        id="cage-marker-fluid"
                        fill={`${fluidColor}`}
                        d="M31.2926620224 27.6463730059C31.2926620224 27.6463730059 31.2926620224 127.242877959 31.2926620224 127.242877959C31.2926620224 127.242877959 104.174002938 127.242877959 104.174002938 127.242877959C104.174002938 127.242877959 104.174002938 27.6463730059 104.174002938 27.6463730059C104.174002938 27.6463730059 31.2926620224 27.6463730059 31.2926620224 27.6463730059C31.2926620224 27.6463730059 31.2926620224 27.6463730059 31.2926620224 27.6463730059"/>

                    <rect id="cage-marker-container"
                          height={fluidLevel} width={73.1} x={31.2} y={27.55}/>
                    <text y={69} x={68} textAnchor="middle" width={135.46667} height={50} fill={textContrast(fluidColor)} dominantBaseline={"central"}>
                        {text}
                    </text>

                </svg>
            </div>
        )
    }

}


CageMarkerItem.propTypes = {
    filled: PropTypes.number.isRequired,
    fluidColor: PropTypes.string,
    text: PropTypes.string
};

CageMarkerItem.defaultProps = {
    fluidColor: "#33cc33",
    filled: 0
};
export default CageMarkerItem



