import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import "./_text-switch.scss";
import TextSwitchItem from "./TextSwitchItem";

class TextSwitch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = TextSwitch.getValues(props)
    }

    static getValues = (props) => {
        return {
            options: _.isArray(props.options) ? props.options : [],
            value: _.isNil(props.value) ? props.options[0] : props.value
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const newValues = TextSwitch.getValues(nextProps);
        if (!_.isEqual(prevState, newValues)) {
            return newValues;
        }
        return null;
    }


    render() {
        const {value, options} = this.state;
        const {onChange, className} = this.props;
        const selectedIndex = options.findIndex(option => _.isEqual(value, option.value));
        return (
            <div
                className={`fetura-text-switch size-${options.length} pos-${selectedIndex}${className ? ` ${className}` : ""}`}>
                {
                    options.map((option, index) => (
                        <TextSwitchItem key={`opt_${index}`} onChange={onChange} selected={selectedIndex === index}
                                        value={option.value} name={option.name}/>
                    ))
                }
            </div>
        );
    }
}

TextSwitch.propTypes = {
    value: PropTypes.oneOf([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

TextSwitch.defaultProps = {
    options: []
};

export default TextSwitch;
