import React from "react";
import {OverlayTrigger, Tooltip as TP} from "react-bootstrap";
import PropTypes from "prop-types"
import {myID} from "../../../libs/generateID";
import "./_tooltip.scss"
import _ from "lodash";

const popperConfig = {modifiers: {preventOverflow: {boundariesElement: 'window'}}};
export default class Tooltip extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id || myID()
        }
    }


    render() {
        const {children, placement, tooltipContent, type} = this.props;
        const {id} = this.state;
        if (_.isNil(tooltipContent)) return children;
        return (
            <OverlayTrigger
                popperConfig={popperConfig}
                placement={placement}
                container={document.getElementById("root")}
                overlay={
                    <TP
                        id={id} className={type}>
                        {tooltipContent}
                    </TP>
                }
            >
                {children}
            </OverlayTrigger>
        );
    }

}

Tooltip.propTypes = {
    tooltipContent: PropTypes.node.isRequired,
    id: PropTypes.string,
    placement: PropTypes.string,
    type: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""])
};

Tooltip.defaultProps = {
    placement: 'top'
};
